import * as React from "react"
import { useState, useLayoutEffect, useRef } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/css/BlogDetail.scss"
import { Link } from "gatsby"
import { AiFillLinkedin } from "react-icons/ai"
import { FaTwitter } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"

import { BlogData } from "../components/blogDetails"

const BetterTogetherHowArtificial = () => {
  // Copy blog data to a state variable
  const [blogList, setBlogList] = React.useState([])

  React.useLayoutEffect(() => {
    setBlogList([...BlogData])
  }, [])

  const ref = useRef(null)

  console.log(ref?.current?.innerText, "ref")

  // Specify the id you want to find
  const targetId = 2

  // Use the find method to get the data for the item with the matching id
  const targetItem = BlogData.find(item => item.id === targetId)

  const wordsPerMinute = 140

  if (targetItem) {
    return (
      <Layout>
        <Seo title="EdgeQ | Better Together: How Artificial Intelligence and 5G Bring Out the Best in Each Other" />

        <section className="News-detail blog-detail" ref={ref}>
          <Container>
            <Row>
              <div className="detail-head">
                <Link to="/blog" className="back-btn">
                  <FiArrowLeft /> Blog Page
                </Link>

                <h2>{targetItem.blogTitle}</h2>
              </div>

              <div className="detail-para">
                <p className="text-left">
                  <i>
                    Batman and Robin. Bonnie and Clyde. 5G and AI.
                  </i>
                </p>

                <p className="text-left ">

                  by <a href="https://www.linkedin.com/in/adil-kidwai-1896366/" target="_blank"> {targetItem.blogBy}</a> | VP of Product Management

                </p>

                <div className="date_time">
                  <h5>{targetItem.blogDate}</h5>
                  <h6>
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 0C11.8869 0 14.1761 0.948211 15.864 2.63604C17.5518 4.32387 18.5 6.61305 18.5 9C18.5 11.3869 17.5518 13.6761 15.864 15.364C14.1761 17.0518 11.8869 18 9.5 18C7.11305 18 4.82387 17.0518 3.13604 15.364C1.44821 13.6761 0.5 11.3869 0.5 9C0.5 6.61305 1.44821 4.32387 3.13604 2.63604C4.82387 0.948211 7.11305 0 9.5 0ZM9.5 0.947368C7.36431 0.947368 5.31609 1.79577 3.80593 3.30593C2.29577 4.81609 1.44737 6.86431 1.44737 9C1.44737 11.1357 2.29577 13.1839 3.80593 14.6941C5.31609 16.2042 7.36431 17.0526 9.5 17.0526C10.5575 17.0526 11.6046 16.8443 12.5816 16.4397C13.5586 16.035 14.4463 15.4418 15.1941 14.6941C15.9418 13.9463 16.535 13.0586 16.9397 12.0816C17.3443 11.1046 17.5526 10.0575 17.5526 9C17.5526 6.86431 16.7042 4.81609 15.1941 3.30593C13.6839 1.79577 11.6357 0.947368 9.5 0.947368ZM9.02632 3.78947H9.97368V8.92421L14.4263 11.4916L13.9526 12.3158L9.02632 9.47368V3.78947Z"
                        fill="#6A6A6A"
                      />
                    </svg>
                    {Math.ceil(
                      ref?.current?.innerText?.split(" ").length /
                      wordsPerMinute
                    )}{" "}
                    min
                    {Math.ceil(
                      ref?.current?.innerText?.split(" ").length /
                      wordsPerMinute
                    ) !== 1
                      ? "s"
                      : ""}
                  </h6>
                </div>

                <div className="ajit-social">
                  <ul className="social-icons">
                    <li>
                      <a
                        aria-label="Save"
                        href="https://www.linkedin.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiFillLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        aria-label="Save"
                        href="https://twitter.com/edgeq_inc?lang=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        aria-label="Save"
                        href="https://www.youtube.com/@EdgeQInc/featured"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="blog_content_padd">
                  <div className="blog_img_one">
                    <img src={targetItem.imgURL} alt={`img-${targetItem.id}`} />
                  </div>

                  {/* <p className="text-left">
                  <i>
                    Lorem ipsum dolor sit consectetur Ut libero urna tellus
                    donec auctor.
                  </i>
                </p> */}



                  <p className="text-left">
                    {targetItem.blogDetails}
                  </p>

                  <p className="text-left">
                    We have heard of AI generating content, giving medical diagnoses, recognizing objects, and enhancing automation and autonomy. We also have heard of 5G bridging the digital divide by allowing the “things that think” to connect wirelessly and frictionlessly to the cloud. The car quickly becomes a computer on wheels, the airplane a data center with wings, and the smartphone becomes a digital wallet. As the world of data expands, so does the need to connect people, devices, and the cloud.
                  </p>

                  {/* <div className="blog_img_one">
                  <img src={targetItem.imgURL1} alt={`img-${targetItem.id}`} />
                </div> */}

                  <h3>5G Enabled by AI</h3>

                  <p className="text-left">
                    Imagine 5G as Batman, ready to tackle industry choke points. AI covers 5G’s back so nothing slips through the cracks. 5G’s connectivity will be enhanced by <span className="orange">on-device AI training</span> so that factors like network traffic, beam pattern optimization, RF spectrum monitoring, and resource allocation are managed better. Imagine an automated manufacturing factory with a constellation of assembly devices all connected to a 5G network. AI algorithms can analyze vast amounts of data generated by the network and provide insights into network usage patterns, traffic congestion, and performance issues. This data analysis can help network operators optimize network traffic, allocate resources more efficiently, and reduce latency, resulting in faster speeds and a better <span className="orange">end</span> user experience.
                  </p>

                  <p className="text-left">
                    This is 5G “enabled and enhanced by AI”. Think about a scheduler algorithm having access to an AI model that predicts user behaviors and knows which user to prioritize and when. 5G users can massively benefit from this enhancement in the scheduling algorithm. Another example is a Radio Frequency chip with access to an AI model that can predict the interference patterns at certain times during the day and therefore tune RF filters appropriately to deal with the interference before the interference occurs. AI has the ability to process a vast amount of data and therefore can give critical information to 5G algorithms and make them “better”
                  </p>

                  <div className="blog_img_one">
                    <img src={targetItem.imgURL2} alt={`img-${targetItem.id}`} />
                  </div>

                  <h3>5G made better by AI</h3>

                  <p className="text-left">
                    Now, let’s swap roles and have AI take the Batman role, and 5G act as the sidekick. AI benefits from 5G in that decision-making is expedited by 5G’s low latency (URLLC) and massive machine-type communications (MMTC). Simply stated, 5G effectively brings the cloud to wherever you are. Communicating the necessary data and analyses to the correct users in a timely manner would boost productivity and instantaneously drive data analysis and synthesis. This is essential for time-sensitive tasks such as object detection/recognition, swarm robotics, traffic management, digital twinning, or spatial computing such as AR/VR.
                  </p>

                  <div className="blog_img_one">
                    <img src={targetItem.imgURL3} alt={`img-${targetItem.id}`} />
                  </div>

                  <h3>What the next generation of 5G + AI looks like today</h3>

                  <p className="text-left">
                    If this all sounds too much like science fiction, these ground-breaking ideas have already begun taking shape. <a className="text-underline" target="_blank" href="https://e.huawei.com/en/case-studies/industries/waterway/tianjin-port">Tianjin Port</a> is one of the world's seven largest ports, with an annual container throughput of 20 million TEU. Tianjin Port uses the latest technology comprised of 5G, autonomous driving, cloud computing, and IoT to create a better connected, smarter, and more autonomous system. As a result, overall costs have been reduced by 30%. Drivers now operate trucks remotely and no longer face dangerous working conditions. The "intelligent port brain" collaborates with key resources to automatically generate optimal loading and unloading plans. The new, intelligent Tianjin Port has become safer, greener, and faster.
                  </p>

                  <p className="text-left">
                    Another example of implementation is the use of swarm robotics in online retail. <a className="text-underline" target="_blank" href="https://www.cambridgeconsultants.com/us/case-studies/wireless-breakthrough-ocado-smart-platform">Ocado</a>, an online grocery retailer, revolutionized their automated warehouses using a private network with unlicensed spectrum. This network allowed thousands of robots to communicate and operate the warehouses, making them “18 times faster, more reliable, and more efficient than traditional warehouses that use conveyor belt systems.”
                  </p>

                  <p className="text-left">
                    As the world becomes increasingly interconnected, the demand for faster and more reliable connectivity + compute continues to grow. Connectivity and computation will need to be reframed in the overall context of the cloud. Connectivity (5G) will play an essential role bridging data generation with data transport with compute processing. Collapsing the friction points will be necessary. Now imagine all this at scale with the next trillion of devices “that think!”
                  </p>

                  {/* <p className="text-left">
                    <a className="text-underline" target="_blank" href="https://www.notion.so/efdaa7d38ac74e3f93859e36f6b278e9">Archive</a>
                  </p> */}
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  } else {
    return <p>Item not found</p>
  }
}
export default BetterTogetherHowArtificial;
